/*---------------
3.1 Material Variables
---------------*/

@import "~@angular/material/theming";

$mat-agente-primary-palette: (
  50: #ffe7e7,
  100: #ffc2c2,
  200: #ff9999,
  300: #ff7070,
  400: #ff5252,
  500: #ff3333,
  600: #ff2e2e,
  700: #ff2727,
  800: #ff2020,
  900: #ff1414,
  A100: #ffffff,
  A200: #fffbfb,
  A400: #ffc8c8,
  A700: #ffaeae,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-agente-warn-palette: (
  50: #e9e9e9,
  100: #c7c7c7,
  200: #a2a2a2,
  300: #7d7d7d,
  400: #616161,
  500: #454545,
  600: #3e3e3e,
  700: #363636,
  800: #2e2e2e,
  900: #1f1f1f,
  A100: #f07a7a,
  A200: #eb4c4c,
  A400: #ff0505,
  A700: #eb0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-agente-accent-palette: (
  50: #ffede7,
  100: #ffd3c2,
  200: #ffb69a,
  300: #fe9971,
  400: #fe8352,
  500: #fe6d34,
  600: #fe652f,
  700: #fe5a27,
  800: #fe5021,
  900: #fd3e15,
  A100: #ffffff,
  A200: #fffaf9,
  A400: #ffcec6,
  A700: #ffb9ad,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary: mat-palette($mat-agente-primary-palette);
$accent: mat-palette($mat-agente-accent-palette);
$warn: mat-palette($mat-agente-warn-palette);
$theme: mat-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@include mat-core();

$dark-primary: mat-palette($mat-blue-grey);
$dark-accent: mat-palette($mat-amber, A200, A100, A400);
$dark-warn: mat-palette($mat-deep-orange);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);
