/* $chat-panel
 ------------------------------------------*/

.chat-panel {
  max-width: 320px;
  width: 100%;
  overflow: hidden;
  mat-tab-group,
  .mat-tab-body-wrapper,
  mat-tab-body,
  .mat-tab-body-content {
    height: 100%;
  }
  .scroll {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
}

mat-sidenav.chat-panel {
  overflow-x: hidden;
  /*box-shadow: $base-card-box-shadow !important;*/
  .mat-sidenav-focus-trap > .cdk-focus-trap-content {
    position: relative;
  }
  .mat-list-item span.mat-list-item-content {
    display: block;
    height: auto;
    max-height: 70px;
    overflow: hidden;
    padding: 16px;
    -webkit-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    -moz-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  }
  .mat-list-item.sub-menu-link span.mat-list-item-content {
    padding: 16px 10px;
  }
  .mat-nav-list .mat-list-item {
    height: auto;
    -webkit-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    -moz-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  }
  .mat-list-item.open > span.mat-list-item-content {
    max-height: 1000px;
    background: #f3f3f3;
  }
  .mat-nav-list a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    /*color: $sidebar-menu-color;*/
    color: #454545;
  }
  .sub-menu {
    //padding-top: 0;
    overflow: hidden;
    -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    transition-property: max-height;
  }
  .navigation {
    .open > .mat-list-item-content > .sub-menu {
      max-height: 1000px;
    }
  }
}
