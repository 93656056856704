/*
//--Theme Info--//

Theme Name: Optima Angular 5 Admin Template
Version: 1.0
Author: TrendSetter Themes


//-- Table of Content --//

---------------
1. Core
---------------
    1.1 Scaffolding
    1.2 Typography
    1.3 Main Sidebar
    1.4 Main Header
    1.5 Main Panel
    1.6 Sessions

---------------
2. Components
---------------
---------------
3. Material
---------------
    3.1 Material Variables
    3.2 Material Theme
    3.3 App Variables
    3.4 Mixin
    3.5 Material

---------------
3. Utilities
---------------*/
@import "./w3.css";
@font-face {
  font-family: "Nexa";
  src: url("../fonts/nexa/Nexa-Light.otf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/nexa/Nexa-Regular.otf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/nexa/Nexa-Bold.otf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/nexa/Nexa-Heavy.otf");
  font-style: normal;
  font-weight: 900;
}
/*---------------
3.1 Material Variables
---------------*/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

/*---------------
3.2 Material Themes
---------------*/
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3333;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fe6d34;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #454545;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ff3333;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #fe6d34;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #454545;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #ff3333;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #fe6d34;
  color: black;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #454545;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #ff3333;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #fe6d34;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #454545;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ff3333;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #fe6d34;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #454545;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #ff3333;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #fe6d34;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #454545;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ff3333;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #fe6d34;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #454545;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ff3333;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #fe6d34;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #454545;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ff3333;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #454545;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #fe6d34;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(255, 51, 51, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 51, 51, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 51, 51, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #ff3333;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 51, 51, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 51, 51, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 51, 51, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(254, 109, 52, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(254, 109, 52, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(254, 109, 52, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #fe6d34;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(254, 109, 52, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(254, 109, 52, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(254, 109, 52, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(69, 69, 69, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(69, 69, 69, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(69, 69, 69, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #454545;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(69, 69, 69, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(69, 69, 69, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(69, 69, 69, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #ff3333;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #fe6d34;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #454545;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #ff3333;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #fe6d34;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #454545;
}

.mat-focused .mat-form-field-required-marker {
  color: #fe6d34;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff3333;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #fe6d34;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #454545;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ff3333;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #fe6d34;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #454545;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #454545;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #454545;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #454545;
}

.mat-error {
  color: #454545;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ff3333;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #fe6d34;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #454545;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #454545;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #ff3333;
}
.mat-icon.mat-accent {
  color: #fe6d34;
}
.mat-icon.mat-warn {
  color: #454545;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #ff3333;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #fe6d34;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #454545;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #454545;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #fbc8c8;
}

.mat-progress-bar-buffer {
  background-color: #fbc8c8;
}

.mat-progress-bar-fill::after {
  background-color: #ff3333;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbd7c9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbd7c9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #fe6d34;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #cdcdcd;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #cdcdcd;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #454545;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ff3333;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #fe6d34;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #454545;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3333;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ff3333;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fe6d34;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #fe6d34;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #454545;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #454545;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ff3333;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #fe6d34;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #454545;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #454545;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fe6d34;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(254, 109, 52, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #fe6d34;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3333;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 51, 51, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ff3333;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #454545;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(69, 69, 69, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #454545;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ff3333;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(255, 51, 51, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #fe6d34;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(254, 109, 52, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #454545;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(69, 69, 69, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ff3333;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #fe6d34;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #454545;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #454545;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #454545;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 194, 194, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff3333;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 211, 194, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #fe6d34;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 199, 199, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #454545;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 194, 194, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ff3333;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 211, 194, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #fe6d34;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 199, 199, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #454545;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #ff3333;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #fe6d34;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #454545;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #fe6d34;
}

.app-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-option {
  color: white;
}
.app-dark .mat-option:hover:not(.mat-option-disabled), .app-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.app-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}
.app-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app-dark .mat-primary .mat-pseudo-checkbox-checked,
.app-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.app-dark .mat-pseudo-checkbox-checked,
.app-dark .mat-pseudo-checkbox-indeterminate,
.app-dark .mat-accent .mat-pseudo-checkbox-checked,
.app-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app-dark .mat-warn .mat-pseudo-checkbox-checked,
.app-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}
.app-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app-dark .mat-app-background, .app-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app-dark .mat-badge-content {
  color: white;
  background: #607d8b;
}
.cdk-high-contrast-active .app-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}
.app-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-button, .app-dark .mat-icon-button, .app-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app-dark .mat-button.mat-primary, .app-dark .mat-icon-button.mat-primary, .app-dark .mat-stroked-button.mat-primary {
  color: #607d8b;
}
.app-dark .mat-button.mat-accent, .app-dark .mat-icon-button.mat-accent, .app-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app-dark .mat-button.mat-warn, .app-dark .mat-icon-button.mat-warn, .app-dark .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.app-dark .mat-button.mat-primary.mat-button-disabled, .app-dark .mat-button.mat-accent.mat-button-disabled, .app-dark .mat-button.mat-warn.mat-button-disabled, .app-dark .mat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-icon-button.mat-primary.mat-button-disabled, .app-dark .mat-icon-button.mat-accent.mat-button-disabled, .app-dark .mat-icon-button.mat-warn.mat-button-disabled, .app-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #607d8b;
}
.app-dark .mat-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app-dark .mat-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.app-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app-dark .mat-button .mat-ripple-element, .app-dark .mat-icon-button .mat-ripple-element, .app-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app-dark .mat-button-focus-overlay {
  background: white;
}
.app-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button, .app-dark .mat-raised-button, .app-dark .mat-fab, .app-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-flat-button.mat-primary, .app-dark .mat-raised-button.mat-primary, .app-dark .mat-fab.mat-primary, .app-dark .mat-mini-fab.mat-primary {
  background-color: #607d8b;
}
.app-dark .mat-flat-button.mat-accent, .app-dark .mat-raised-button.mat-accent, .app-dark .mat-fab.mat-accent, .app-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-flat-button.mat-warn, .app-dark .mat-raised-button.mat-warn, .app-dark .mat-fab.mat-warn, .app-dark .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-flat-button.mat-primary.mat-button-disabled, .app-dark .mat-flat-button.mat-accent.mat-button-disabled, .app-dark .mat-flat-button.mat-warn.mat-button-disabled, .app-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-raised-button.mat-primary.mat-button-disabled, .app-dark .mat-raised-button.mat-accent.mat-button-disabled, .app-dark .mat-raised-button.mat-warn.mat-button-disabled, .app-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-dark .mat-fab.mat-primary.mat-button-disabled, .app-dark .mat-fab.mat-accent.mat-button-disabled, .app-dark .mat-fab.mat-warn.mat-button-disabled, .app-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-flat-button.mat-primary .mat-ripple-element, .app-dark .mat-raised-button.mat-primary .mat-ripple-element, .app-dark .mat-fab.mat-primary .mat-ripple-element, .app-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-flat-button.mat-accent .mat-ripple-element, .app-dark .mat-raised-button.mat-accent .mat-ripple-element, .app-dark .mat-fab.mat-accent .mat-ripple-element, .app-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-flat-button.mat-warn .mat-ripple-element, .app-dark .mat-raised-button.mat-warn .mat-ripple-element, .app-dark .mat-fab.mat-warn .mat-ripple-element, .app-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone,
.app-dark .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-dark .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.app-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-card {
  background: #424242;
  color: white;
}
.app-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #607d8b;
}
.app-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}
.app-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #607d8b;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}
.app-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-dark .mat-table {
  background: #424242;
}
.app-dark .mat-table thead, .app-dark .mat-table tbody, .app-dark .mat-table tfoot,
.app-dark mat-header-row, .app-dark mat-row, .app-dark mat-footer-row,
.app-dark [mat-header-row], .app-dark [mat-row], .app-dark [mat-footer-row],
.app-dark .mat-table-sticky {
  background: inherit;
}
.app-dark mat-row, .app-dark mat-header-row, .app-dark mat-footer-row,
.app-dark th.mat-header-cell, .app-dark td.mat-cell, .app-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-cell, .app-dark .mat-footer-cell {
  color: white;
}
.app-dark .mat-calendar-arrow {
  fill: white;
}
.app-dark .mat-datepicker-toggle,
.app-dark .mat-datepicker-content .mat-calendar-next-button,
.app-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-calendar-table-header,
.app-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-calendar-body-cell-content,
.app-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.app-dark .mat-calendar-body-comparison-identical,
.app-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-calendar-body-comparison-bridge-start::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-comparison-bridge-end::before,
.app-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.app-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(96, 125, 139, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(96, 125, 139, 0.3);
  }
}
.app-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 215, 64, 0.3);
  }
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
@media (hover: hover) {
  .app-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 87, 34, 0.3);
  }
}
.app-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-datepicker-toggle-active {
  color: #607d8b;
}
.app-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}
.app-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app-dark .mat-expansion-panel-header-title {
  color: white;
}
.app-dark .mat-expansion-panel-header-description,
.app-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #607d8b;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}
.app-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app-dark .mat-form-field-ripple {
  background-color: white;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #607d8b;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #607d8b;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.app-dark .mat-error {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #607d8b;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app-dark .mat-icon.mat-primary {
  color: #607d8b;
}
.app-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app-dark .mat-icon.mat-warn {
  color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-input-element:disabled,
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element {
  caret-color: #607d8b;
}
.app-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app-dark .mat-form-field.mat-warn .mat-input-element,
.app-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}
.app-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}
.app-dark .mat-list-base .mat-list-item {
  color: white;
}
.app-dark .mat-list-base .mat-list-option {
  color: white;
}
.app-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-list-option:hover, .app-dark .mat-list-option:focus,
.app-dark .mat-nav-list .mat-list-item:hover,
.app-dark .mat-nav-list .mat-list-item:focus,
.app-dark .mat-action-list .mat-list-item:hover,
.app-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-list-single-selected-option, .app-dark .mat-list-single-selected-option:hover, .app-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-menu-panel {
  background: #424242;
}
.app-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app-dark .mat-menu-item[disabled],
.app-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-menu-item .mat-icon-no-color,
.app-dark .mat-menu-submenu-icon {
  color: white;
}
.app-dark .mat-menu-item:hover:not([disabled]),
.app-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-paginator {
  background: #424242;
}
.app-dark .mat-paginator,
.app-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-paginator-decrement,
.app-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app-dark .mat-paginator-first,
.app-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app-dark .mat-icon-button[disabled] .mat-paginator-first,
.app-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-progress-bar-background {
  fill: #3c4347;
}
.app-dark .mat-progress-bar-buffer {
  background-color: #3c4347;
}
.app-dark .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645a34;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645a34;
}
.app-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643a2d;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643a2d;
}
.app-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}
.app-dark .mat-progress-spinner circle, .app-dark .mat-spinner circle {
  stroke: #607d8b;
}
.app-dark .mat-progress-spinner.mat-accent circle, .app-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app-dark .mat-progress-spinner.mat-warn circle, .app-dark .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}
.app-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.app-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.app-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.app-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-select-value {
  color: white;
}
.app-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-select-panel {
  background: #424242;
}
.app-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #607d8b;
}
.app-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.app-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.app-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.app-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.app-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app-dark .mat-slider.mat-primary .mat-slider-thumb,
.app-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #607d8b;
}
.app-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.app-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app-dark .mat-slider.mat-accent .mat-slider-thumb,
.app-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app-dark .mat-slider.mat-warn .mat-slider-thumb,
.app-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.app-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}
.app-dark .mat-slider:hover .mat-slider-track-background,
.app-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app-dark .mat-step-header.cdk-keyboard-focused, .app-dark .mat-step-header.cdk-program-focused, .app-dark .mat-step-header:hover:not([aria-disabled]), .app-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app-dark .mat-step-header:hover {
    background: none;
  }
}
.app-dark .mat-step-header .mat-step-label,
.app-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-selected,
.app-dark .mat-step-header .mat-step-icon-state-done,
.app-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #607d8b;
  color: white;
}
.app-dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.app-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}
.app-dark .mat-stepper-horizontal, .app-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-horizontal-stepper-header::before,
.app-dark .mat-horizontal-stepper-header::after,
.app-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app-dark .mat-tab-nav-bar,
.app-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app-dark .mat-tab-label, .app-dark .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.app-dark .mat-tab-group.mat-primary .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #607d8b;
}
.app-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-accent .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-warn .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #607d8b;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app-dark .mat-toolbar.mat-primary {
  background: #607d8b;
  color: white;
}
.app-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app-dark .mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.app-dark .mat-toolbar .mat-form-field-underline,
.app-dark .mat-toolbar .mat-form-field-ripple,
.app-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app-dark .mat-toolbar .mat-form-field-label,
.app-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app-dark .mat-toolbar .mat-select-value,
.app-dark .mat-toolbar .mat-select-arrow,
.app-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app-dark .mat-tree {
  background: #424242;
}
.app-dark .mat-tree-node,
.app-dark .mat-nested-tree-node {
  color: white;
}
.app-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-dark .mat-simple-snackbar-action {
  color: inherit;
}

/*---------------
3.3 App Variables
---------------*/
/*---------------
3.4 Mixins
---------------*/
/*---------------
1. Core
---------------*/
/*---------------
1.1 Scaffolding
---------------*/
html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: "Nexa", "Helvetica Neue", sans-serif;
}
body.pace-done {
  background: #6b6b6b;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card.settings-panel {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 350px;
  z-index: 9;
  top: 58px;
}

.bg-img > div {
  float: left;
  width: 50%;
  padding: 0.75rem;
  border-radius: 10px;
}
.bg-img > div img {
  border-radius: 10px;
  box-shadow: 1.5px 2.6px 13px 0 rgba(0, 35, 136, 0.3);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

*,
::after,
::before {
  box-sizing: border-box;
}

.demo-checkbox .mat-checkbox,
.demo-checkbox .mat-checkbox-layout {
  width: 100%;
}
.demo-checkbox .mat-checkbox-layout .mat-checkbox-label {
  flex: 1;
  box-sizing: border-box;
  -webkit-box-flex: 1;
}

/*---------------
1.2 Typography
---------------*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}
a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

strong, b {
  font-weight: 700;
}

/*
---------------
1.3 Sidebar Panel
---------------*/
mat-sidenav.sidebar-panel {
  overflow-x: hidden;
  width: 15rem;
  /*box-shadow: $base-card-box-shadow !important;*/
}
mat-sidenav.sidebar-panel .mat-sidenav-focus-trap > .cdk-focus-trap-content {
  position: relative;
}
mat-sidenav.sidebar-panel > nav {
  min-width: 15rem;
}
mat-sidenav.sidebar-panel .mat-list-item span.mat-list-item-content {
  display: block;
  height: auto;
  max-height: 48px;
  overflow: hidden;
  padding: 0;
  -webkit-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel .mat-nav-list .mat-list-item {
  height: auto;
  background-color: transparent;
  -webkit-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.sidebar-panel .mat-list-item.open > span.mat-list-item-content {
  max-height: 1000px;
  background: rgba(253, 253, 253, 0.2);
}
mat-sidenav.sidebar-panel .mat-nav-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  color: #fff;
}
mat-sidenav.sidebar-panel .sub-menu {
  padding-top: 0;
  overflow: hidden;
  -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  transition-property: max-height;
}
mat-sidenav.sidebar-panel .sub-menu a {
  padding-left: 64px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-left: 80px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-left: 96px;
}
mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-left: 112px;
}
mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-right: 20px;
  color: #fff;
}
mat-sidenav.sidebar-panel .navigation .menu-caret {
  display: inline-block;
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: -moz-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: -o-transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  transition: transform 300ms cubic-bezier(0.7, 0, 0.3, 1);
  float: right;
  margin-left: 0.3125rem;
  text-align: center;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item-content > [appAccordionToggle] > .menu-caret {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
mat-sidenav.sidebar-panel .navigation .menu-badge {
  display: inline-block;
  height: 16px;
  min-width: 10px;
  line-height: 18px;
  text-align: center;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 700;
  padding: 0 4px;
}
mat-sidenav.sidebar-panel .navigation .open > .mat-list-item-content > .sub-menu {
  max-height: 1000px;
}

.mat-drawer-content {
  display: flex !important;
}

.user-profile-thumb img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.user-pro-wrap {
  padding: 1rem;
  position: relative;
  border-style: solid;
  border-width: 1px 0;
  border-color: #e1e1e1ad;
  margin-top: 10px;
  /*
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);*/
}

.branding {
  padding: 1.5rem 0;
}
.branding h2 {
  color: #fff;
  margin: 0;
}

.most-link {
  display: inline-block;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 12px;
}
.most-link .mat-icon {
  margin-top: 0.8rem;
}

.sidebar-panel {
  background-image: url("../../../../assets/images/login-background.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.mat-card.settings-panel .demo-checkbox {
  height: 100% !important;
  overflow-y: auto;
}

[mat-fab].mat-fab-bottom-right {
  top: 47% !important;
  right: 0 !important;
  position: fixed !important;
  border-radius: 0 !important;
  z-index: 9;
}

.user-i {
  z-index: 99;
  position: relative;
}
.user-i span {
  font-weight: bold;
  font-size: 1rem;
  padding: 0 0.5rem;
  letter-spacing: 0.05em;
}

.bg-overlay {
  position: absolute;
  left: 0;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.85;
  background-image: linear-gradient(58deg, #da3c3c, #ff3333);
}

.mat-sidenav-content {
  overflow-x: hidden !important;
  padding: 0 !important;
  background: #f2f4f8;
  display: flex;
}

.body-container {
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 1rem 2rem;
}

@media (max-width: 991px) {
  .body-container {
    padding: 1rem;
  }

  html {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .body-container {
    padding: 0rem;
  }
}
body .opt-side {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
body .app {
  height: 100%;
  overflow: hidden;
}
body .app-inner {
  height: 100%;
}
body .main-nav {
  height: calc(100% - 144px);
}

.m-gap {
  margin-bottom: 1.5rem;
}

.p-gap {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 960px) {
  /* Collapsed sidebar */
  .collapsed-sidebar .mat-sidenav-backdrop {
    display: none;
  }
  .collapsed-sidebar .branding span {
    display: none;
  }
  .collapsed-sidebar .user-i span {
    display: none;
  }
  .collapsed-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
    margin-left: 80px !important;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) {
    width: 80px;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .sub-menu {
    display: none !important;
    visibility: hidden;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
.collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > .menu-caret {
    display: none;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    position: absolute;
    top: 10px;
    right: 16px;
  }
  .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > .material-icons {
    width: 80px !important;
    padding: 0;
    margin: 0;
  }

  /* Compact sidebar */
  .compact-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
    margin-left: 100px !important;
  }
  .compact-sidebar .opt-side {
    height: 100%;
    overflow: visible;
  }
  .compact-sidebar.app {
    overflow: auto;
    height: auto;
  }
  .compact-sidebar .branding span {
    display: none;
  }
  .compact-sidebar .user-i span {
    display: none;
  }
  .compact-sidebar mat-sidenav.sidebar-panel {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }
  .compact-sidebar mat-sidenav.sidebar-panel {
    width: 100px;
    overflow: visible !important;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content {
    height: 60px;
    max-height: 60px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item {
    position: relative;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content {
    overflow: visible;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a {
    padding-right: 0;
    padding-left: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    box-sizing: content-box !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    height: 42px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge):not(.menu-caret) {
    font-size: 12px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    position: absolute;
    top: 10px;
    right: 16px;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > .material-icons {
    width: 100px !important;
    padding: 0;
    margin: 0;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item:hover > .mat-list-item-content > .sub-menu {
    display: block !important;
    visibility: visible;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .mat-list-item > .mat-list-item-content > a > .menu-caret,
.compact-sidebar mat-sidenav.sidebar-panel .mat-list-item > .mat-list-item-content > a > [fxflex] {
    display: none;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    display: none !important;
    visibility: hidden;
    background: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 0;
    left: 100%;
    width: 150px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .compact-sidebar mat-sidenav.sidebar-panel .sub-menu a {
    padding-left: 16px;
    height: 36px;
    color: rgba(0, 0, 0, 0.87);
  }
  .compact-sidebar .sidebar-panel .mat-drawer-inner-container {
    overflow: visible !important;
  }
}
.header-green .mat-toolbar.main-header {
  background: #009688 !important;
}

.color-green {
  color: #009688;
}

.header-red .mat-toolbar.main-header {
  background: #f44336 !important;
}

.color-red {
  color: #f44336;
}

.header-purple .mat-toolbar.main-header {
  background: #9c27b0 !important;
}

.color-purple {
  color: #9c27b0;
}

.header-pink .mat-toolbar.main-header {
  background: #e91e63 !important;
}

.color-pink {
  color: #e91e63;
}

.header-blue .mat-toolbar.main-header {
  background: #2196f3 !important;
}

.color-blue {
  color: #2196f3;
}

.header-green-deep .mat-toolbar.main-header {
  background: #43a047 !important;
}

.color-green-deep {
  color: #43a047;
}

.header-brown .mat-toolbar.main-header {
  background: #795548 !important;
}

.color-brown {
  color: #795548;
}

.header-orange .mat-toolbar.main-header {
  background: #ff5722 !important;
}

.color-orange {
  color: #ff5722;
}

body .bg-img-disable .sidebar-panel {
  background: none !important;
}

.sidebar-green .sidebar-panel .bg-overlay {
  background: rgba(0, 150, 136, 0.8) !important;
}

.sidebar-red .sidebar-panel .bg-overlay {
  background: rgba(244, 67, 54, 0.8) !important;
}

.sidebar-purple .sidebar-panel .bg-overlay {
  background: rgba(156, 39, 176, 0.8) !important;
}

.sidebar-pink .sidebar-panel .bg-overlay {
  background: rgba(233, 30, 99, 0.8) !important;
}

.sidebar-blue .sidebar-panel .bg-overlay {
  background: rgba(33, 150, 243, 0.8) !important;
}

.sidebar-green-deep .sidebar-panel .bg-overlay {
  background: rgba(67, 160, 71, 0.8) !important;
}

.sidebar-brown .sidebar-panel .bg-overlay {
  background: rgba(121, 85, 72, 0.8) !important;
}

.sidebar-orange .sidebar-panel .bg-overlay {
  background: rgba(255, 87, 34, 0.8) !important;
}

.bg-none.bg-4 .sidebar-panel,
.bg-none.bg-3 .sidebar-panel,
.bg-none.bg-2 .sidebar-panel,
.bg-none.bg-1 .sidebar-panel {
  background-image: none !important;
}

.color-sidebar .mb-1,
.color-header .mb-1 {
  display: inline-block;
  width: 49%;
}

.demo-checkbox img,
.demo-checkbox i {
  border: 3px solid transparent;
}
.demo-checkbox .border-active img,
.demo-checkbox .border-active i {
  border: 3px solid #ffd740;
}
.demo-checkbox .border-active i {
  border-radius: 10px;
}

[dir=rtl] mat-sidenav.sidebar-panel .sub-menu a {
  padding-right: 64px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu a {
  padding-right: 80px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu a {
  padding-right: 96px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .sub-menu .sub-menu .sub-menu .sub-menu a {
  padding-right: 112px;
  padding-left: 16px;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation mat-icon:not(.menu-caret) {
  margin-left: 24px;
  margin-right: 0;
}
[dir=rtl] mat-sidenav.sidebar-panel .navigation .menu-caret {
  float: left;
  margin-right: 0.3125rem;
  margin-left: 0;
}
[dir=rtl] .img-box ul {
  left: 5%;
}
[dir=rtl] .social {
  margin: 90px 24% !important;
}
[dir=rtl] .p-list-main .p-list .bottom .left {
  float: right;
}
@media (max-width: 1024px) {
  [dir=rtl] .social {
    margin: 90px 15% !important;
  }
}

@media (min-width: 960px) {
  .side-panel-opened[dir=rtl] .mat-drawer-content {
    margin-left: 0 !important;
    margin-right: 15rem !important;
  }

  [dir=rtl].collapsed-sidebar mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content,
[dir=rtl] .collapsed-sidebar mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content {
    margin-left: 0 !important;
    margin-right: 80px !important;
  }
  [dir=rtl].collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge,
[dir=rtl] .collapsed-sidebar mat-sidenav.sidebar-panel:not(:hover) .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    right: auto;
    left: 16px;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content {
    margin-left: auto !important;
    margin-right: 100px !important;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
    right: auto;
    left: 16px;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .sub-menu,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    left: auto;
    right: 100%;
  }
  [dir=rtl].compact-sidebar mat-sidenav.sidebar-panel .sub-menu a,
[dir=rtl] .compact-sidebar mat-sidenav.sidebar-panel .sub-menu a {
    padding-left: 16px;
    padding-right: 16px;
  }

  .app-dark.compact-sidebar mat-sidenav.sidebar-panel .sub-menu,
.app-dark .compact-sidebar mat-sidenav.sidebar-panel .sub-menu {
    background: #424242;
  }
}
.app {
  overflow-x: hidden;
}

/* Main*/
.search-in .search-form {
  transform: translateY(0%) !important;
}

.widget-icon {
  width: 50px;
  height: 50px;
  line-height: 65px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin: 0 1rem;
  background: rgba(255, 255, 255, 0.1);
}

.grid-corner {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px 12px 22px 24px;
  border-radius: 0 0 0 100%;
  transition: all 0.3s ease-in-out 0s;
}

.card-header h5,
h3 {
  margin-top: 0;
}

.base-card .fa-lg {
  transition: all 0.3s ease-in-out 0s;
}
.base-card:hover .fa-lg {
  font-size: 2rem;
}

.base-text i {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
}

.pro-card {
  padding: 3rem 2rem;
}

.pro-img img {
  border-radius: 100%;
  box-shadow: 1.5px 2.6px 24px 0 rgba(0, 35, 136, 0.08);
}

.profile-share {
  padding: 0;
}
.profile-share li {
  display: inline-block;
}
.profile-share li a {
  font-size: 1rem;
  padding: 0 0.2rem;
}

.wheather-widget .wheather-today {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.wheather-widget .wheather-today i {
  font-size: 6rem;
}
.wheather-widget .wheather-future {
  padding: 1rem 0;
}
.wheather-widget .wheather-future i {
  font-size: 3rem;
}
.wheather-widget .wheather-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.wheather-widget .wheather-child:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.w-50 {
  width: 50px;
}

.time {
  display: block;
}

.comment-time {
  display: block;
  right: 1rem;
  font-size: 78%;
  margin-left: 2rem;
  opacity: 0.7;
}

.message-content .shadow-none.mat-card {
  padding: 1rem;
}

.mat-row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mat-pad {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: 580px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body .p-2 {
    padding: 1rem !important;
  }
}
body .mat-card > :last-child {
  border: 2px;
}

body .sidebar-container {
  height: 100%;
  position: relative;
}

@media (max-width: 543px) {
  .search-bar {
    width: 110px;
  }

  .search-bar.search-active {
    width: calc(100% - 60px);
    position: absolute;
    flex: 1 1 0%;
    z-index: 99;
    left: 50px !important;
    right: auto !important;
  }

  .sm-disable {
    display: none !important;
  }

  .search-form .mat-icon {
    display: none;
  }
  .search-form input {
    padding: 0.95rem 0.5rem !important;
    text-indent: 0 !important;
    font-size: 0.8rem !important;
  }
}
[dir=rtl] .comment-time {
  margin-right: 2rem;
}

/*
---------------
1.4 Header
---------------*/
mat-toolbar .toolbar-avatar {
  width: 40px;
  height: 40px;
  line-height: 24px;
}
mat-toolbar .toolbar-avatar img {
  width: 40px;
  border-radius: 50%;
}
mat-toolbar .notification-label {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.8125rem;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: #454545;
  color: white;
  text-align: center;
}
mat-toolbar.main-header {
  padding: 0 0.3125rem;
  background: transparent;
  position: relative;
  box-shadow: 0 7px 6px rgba(0, 0, 0, 0.05);
  background: #fff;
  z-index: 1;
}
mat-toolbar.main-header .search-bar {
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out 0s;
}
mat-toolbar.main-header .search-bar .search-form {
  background: white;
  position: relative;
  border-radius: 2px;
  margin-right: 1rem;
  display: block;
  margin: 0 0.3125rem;
}
mat-toolbar.main-header .search-bar .search-form input {
  font-size: 1rem;
  padding: 0.95rem 0.75rem;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
  border: 1px solid #eeeeee;
}
mat-toolbar.main-header .search-bar .search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.branding {
  height: 64px;
  padding: 1rem 0;
}
.branding h2 {
  margin: 0;
  font-size: 1.8rem;
}

.search-in .search-bar {
  transform: translateY(0%) !important;
}

.opt-menu .mat-menu-content {
  padding-top: 0;
}
.opt-menu .head-menu {
  font-size: 1.3rem !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(58deg, #f44336, #ff3333);
  color: #fff;
}
.opt-menu .head-menu:hover {
  background: linear-gradient(58deg, #f44336, #ff3333);
}
.opt-menu img {
  border-radius: 100%;
  box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.6) !important;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 0.4rem;
}

@media (max-width: 599px) and (orientation: portrait) {
  mat-toolbar.main-header .branding {
    padding: 0 16px 0 16px;
  }
  mat-toolbar.main-header .logo {
    width: 87px;
    height: 20px;
    -webkit-background-size: 87px 20px;
    background-size: 87px 20px;
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  mat-toolbar.main-header .search-bar .search-form input {
    padding: 0.65rem 0.75rem;
  }
}
[dir=rtl] .main-header .branding {
  padding: 0 16px 0 64px;
}
[dir=rtl] .main-header .search-bar .search-form .material-icons {
  left: auto;
  right: 10px;
}

.messages-list .mat-expansion-indicator {
  display: none;
}

@media (max-width: 768px) {
  .name-user {
    display: none;
  }

  .img-thumb img {
    margin-right: 3rem !important;
  }
}
@media (max-width: 600px) {
  mat-toolbar.main-header {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  }
}
/*
---------------
1.5 Main Panel
---------------*/
.app-inner {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 599px) and (orientation: portrait) {
  .app-inner {
    height: calc(100vh - 56px);
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  .app-inner {
    height: calc(100vh - 48px);
  }
}
/* Boxed layout*/
.app.boxed {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .app.boxed {
    max-width: 46.88rem;
  }
}
@media (min-width: 62rem) {
  .app.boxed {
    max-width: 60.63rem;
  }
}
@media (min-width: 75rem) {
  .app.boxed {
    max-width: 73.13rem;
  }
}
.mat-sidenav-content {
  min-height: 100%;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.3333333333rem;
}
.mat-sidenav-content > .main-content {
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mat-sidenav-content > .main-content > .content-view {
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.app-dark .mat-sidenav-content {
  background-color: #353535;
}

.top-menu .horizontal-top-bar {
  display: flex;
}
.top-menu .horizontal-logo {
  display: block;
}
.top-menu .sidebar-panel {
  display: none;
}
.top-menu .mat-drawer-content.mat-sidenav-content {
  margin: 0 !important;
  overflow: hidden;
}
.top-menu .horizontal-menu {
  background: #fff;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
}
.top-menu .horizontal-menu .main-h-list {
  padding: 0;
  margin: 0;
}
.top-menu .horizontal-menu .main-h-list > li {
  display: inline-block;
}
.top-menu .horizontal-menu .main-h-list > li > a {
  display: flex;
  padding: 1rem 1rem;
  margin: 0 0.5rem;
}
.top-menu .horizontal-menu .main-h-list > li:hover > .dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.top-menu .horizontal-menu .main-h-list li {
  position: relative;
}
.top-menu .horizontal-menu .main-h-list .dropdown {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 200px;
  transition: all 0.2s ease-in-out 0s;
  transform: translateY(-20px);
  background: #fff;
  padding: 0;
  list-style: none;
  text-align: left;
  z-index: 9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.top-menu .horizontal-menu .main-h-list .dropdown a {
  padding: 0.5rem 1rem;
  display: block;
}
.top-menu .horizontal-menu .main-h-list .dropdown a:hover {
  background: #f2f4f8;
}
.top-menu .horizontal-menu .main-h-list .dropdown li:hover > .dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.top-menu .horizontal-menu .main-h-list .dropdown .dropdown {
  top: 0;
  left: 100%;
}
.top-menu .horizontal-menu .main-h-list .mat-icon {
  margin-right: 0.5rem;
}
.top-menu .horizontal-logo .branding h2 {
  font-weight: 700;
  background-image: linear-gradient(58deg, #f44336, #FF3333);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  background-clip: text;
}
.top-menu .vertical-menu {
  display: none !important;
}
.top-menu .horizontal-top-bar > div {
  align-self: center;
}
.top-menu .menu-links {
  text-align: right;
}
.top-menu.collapsed-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
  margin-left: 0 !important;
}
.top-menu.compact-sidebar.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
  margin-left: 0 !important;
}
.top-menu.compact-sidebar .branding span {
  display: inline-block;
}
.top-menu.collapsed-sidebar .branding span {
  display: inline-block;
}

.vertical-menu {
  align-items: center;
}
.vertical-menu .horizontal-top-bar, .vertical-menu .horizontal-menu {
  display: none !important;
}

@media (max-width: 960px) {
  .top-menu .vertical-menu {
    display: flex !important;
  }
  .top-menu .horizontal-top-bar {
    display: none !important;
  }
  .top-menu .sidebar-panel {
    display: flex;
  }
  .top-menu .horizontal-menu {
    display: none !important;
  }
}
.app-dark.top-menu .horizontal-menu {
  background: transparent;
}
.app-dark.top-menu .main-h-list .dropdown {
  background: #424242;
}
.app-dark.top-menu .main-h-list .dropdown a:hover {
  background: #353535;
}

[dir=rtl].top-menu .horizontal-logo .branding h2 {
  line-height: 64px;
}
[dir=rtl].top-menu .menu-links {
  text-align: left;
}
[dir=rtl].top-menu .horizontal-menu .main-h-list .dropdown {
  text-align: right;
}
[dir=rtl].top-menu .horizontal-menu .main-h-list .dropdown .dropdown {
  left: -100%;
}

/* $chat-panel
 ------------------------------------------*/
.chat-panel {
  max-width: 320px;
  width: 100%;
  overflow: hidden;
}
.chat-panel mat-tab-group,
.chat-panel .mat-tab-body-wrapper,
.chat-panel mat-tab-body,
.chat-panel .mat-tab-body-content {
  height: 100%;
}
.chat-panel .scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

mat-sidenav.chat-panel {
  overflow-x: hidden;
  /*box-shadow: $base-card-box-shadow !important;*/
}
mat-sidenav.chat-panel .mat-sidenav-focus-trap > .cdk-focus-trap-content {
  position: relative;
}
mat-sidenav.chat-panel .mat-list-item span.mat-list-item-content {
  display: block;
  height: auto;
  max-height: 70px;
  overflow: hidden;
  padding: 16px;
  -webkit-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.chat-panel .mat-list-item.sub-menu-link span.mat-list-item-content {
  padding: 16px 10px;
}
mat-sidenav.chat-panel .mat-nav-list .mat-list-item {
  height: auto;
  -webkit-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
mat-sidenav.chat-panel .mat-list-item.open > span.mat-list-item-content {
  max-height: 1000px;
  background: #f3f3f3;
}
mat-sidenav.chat-panel .mat-nav-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  /*color: $sidebar-menu-color;*/
  color: #454545;
}
mat-sidenav.chat-panel .sub-menu {
  overflow: hidden;
  -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  transition-property: max-height;
}
mat-sidenav.chat-panel .navigation .open > .mat-list-item-content > .sub-menu {
  max-height: 1000px;
}

/*
---------------
1.6 Sessions
---------------*/
.about-w {
  background-image: url("/../../../assets/images/about.jpg");
}

.form-bg {
  background-image: url("/../../../assets/images/login-two.png");
}

.form-bg {
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  background-size: cover;
  background-position: top center;
  height: auto;
}

.shared-icon {
  margin: 0.3rem;
  padding: 0.8rem 2rem;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
}

.form-bg::before {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

body .login-session {
  position: relative;
  z-index: 99;
  padding: 2rem !important;
}

.text-white {
  color: #fff;
}

.title-block {
  height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hero {
  position: relative;
}
.hero .hero-overlay {
  background-color: rgba(60, 59, 63, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.forgot-img {
  box-shadow: 1.5px 2.6px 19px 0 rgba(0, 22, 17, 0.8);
}

.session {
  position: relative;
  z-index: 4000;
  min-height: calc(100vh + 100px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.error-text {
  font-size: 12rem;
  display: inline-block;
  color: #fff;
  line-height: normal;
}

.error-wrapper {
  text-align: center;
  z-index: 99;
  padding-top: 5rem;
  color: #fff;
}

.session-content {
  padding: 40px 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}

.session-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  -webkit-box-flex: none;
  -webkit-flex: none;
  -moz-box-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: #ff3333;
  color: #fff;
  padding: 0 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 1rem;
}
.error-title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #ff3333;
  border-width: 8px;
  margin-left: -8px;
}

.error-subtitle {
  font-size: 32px;
  font-weight: 300;
}

.notfound {
  background-image: url("/../../../assets/images/notfound.jpg");
}

/*---------------
2. Components
---------------*/
/*---------------
Perfect Scrollbar
---------------*/
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 15px;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  bottom: 2px;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 6px;
}
.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}
.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 15px;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  right: 2px;
  /* there must be 'right' for ps-scrollbar-y */
  width: 6px;
}
.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

/* $Widget
 ------------------------------------------*/
.ngx-datatable.material {
  padding: 0;
  margin: 0.3333333333rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: block;
  position: relative;
  border-radius: 2px;
  /*&:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background: mat-color($background, "hover");
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat-color($background, "hover"), 2%);
      }
    }
  }*/
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable.material.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.page {
  height: 65vh;
  max-height: 550px;
  min-height: 350px;
}
.ngx-datatable.material.min {
  height: 55vh;
  max-height: 450px;
  min-height: 350px;
}
.ngx-datatable.material.single-selection .datatable-body-row, .ngx-datatable.material.multi-selection .datatable-body-row {
  /*&.active,
  &.active .datatable-row-group {
    @include _mat-toolbar-color($primary);
  }*/
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #ff2929;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #ff1f1f;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell {
  /*&.active,
  &.active .datatable-row-group {
    @include _mat-toolbar-color($primary);
  }*/
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #ff2929;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #ff1f1f;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  padding: 0.85rem 1.2rem;
}
.ngx-datatable.material .loading-row {
  padding: 0.85rem 1.2rem;
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 100%;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 0.85rem 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  vertical-align: middle;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress {
  color: #00e676;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-body {
  min-width: 100%;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.85rem 1.2rem;
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  -webkit-box-pack: start;
  -webkit-box-align: center;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
  width: 100%;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #fc585881;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #ff3333;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 40px;
  height: 40px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li a {
  height: 20px;
  min-width: 20px;
  margin: 0px 3px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background: #ff3333;
  color: white;
  font-weight: 400;
}

.app-dark .ngx-datatable.material {
  background: #424242;
  color: white;
  /**
   * Header Styles
   */
  /**
   * Footer Styles
   */
}
.app-dark .ngx-datatable.material.striped .datatable-row-odd {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row {
  /*&.active,
  &.active .datatable-row-group {
    @include _mat-toolbar-color($dark-primary);
  }*/
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #5c7885;
}
.app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .app-dark .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .app-dark .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #58727f;
}
.app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .app-dark .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: rgba(250, 250, 250, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell {
  /*&.active,
  &.active .datatable-row-group {
    @include _mat-toolbar-color($dark-primary);
  }*/
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(250, 250, 250, 0.04);
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #5c7885;
}
.app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .app-dark .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #58727f;
}
.app-dark .ngx-datatable.material .datatable-header .datatable-row-left,
.app-dark .ngx-datatable.material .datatable-body .datatable-row-left,
.app-dark .ngx-datatable.material .datatable-header .datatable-row-right,
.app-dark .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #494949;
}
.app-dark .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .ngx-datatable.material .datatable-header .datatable-header-cell {
  color: white;
}
.app-dark .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app-dark .ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.app-dark .ngx-datatable.material .datatable-footer .page-count {
  line-height: 40px;
  height: 40px;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.26) !important;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li a {
  color: white;
  height: 20px;
  min-width: 20px;
  margin: 0px 3px;
}
.app-dark .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background: #607d8b;
}

[dir=rtl] .ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: right;
}
[dir=rtl] .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: right;
}
[dir=rtl] .ngx-datatable.material .empty-row {
  text-align: right;
}
[dir=rtl] .ngx-datatable.material .loading-row {
  text-align: right;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager {
  text-align: left;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ff3333;
}

@media (max-width: 700px) {
  .ngx-datatable.material {
    margin: 0;
    box-shadow: none !important;
  }
  .ngx-datatable.material.scroll-vertical {
    height: 70vh;
    max-height: 550px;
    min-height: 350px;
  }
}
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #FFFFFF;
  height: 506px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.post-module:hover,
.hover {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}

.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.6;
}

.post-module .thumbnail {
  background: #000000;
  height: 400px;
  overflow: hidden;
}

.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 5.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.post-module .thumbnail .date .day {
  font-size: 18px;
}

.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}

.post-module .thumbnail img {
  display: block;
  width: 120%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 30px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #e74c3c;
  padding: 10px 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 26px;
  font-weight: 700;
}

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}

.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}

.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}

.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}

.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}

.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.container {
  max-width: 800px;
  min-width: 640px;
  margin: 0 auto;
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .column {
  width: 50%;
  padding: 0 25px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}

.container .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.container .info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.container .info span {
  color: #666666;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #e74c3c;
}

.hover-parent {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
  transition: 0.3s ease-in-out 0s;
  position: relative;
  overflow: hidden;
}
.hover-parent .hover-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  opacity: 0;
  transition: 0.3s ease-in-out 0s;
  right: 0;
  bottom: 0;
}
.hover-parent .hover-overlay .hover-overlay-content {
  color: #fff;
  transition: 0.3s ease-in-out 0s;
  transform: translateY(60px);
}
.hover-parent:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.07);
}
.hover-parent:hover .hover-overlay {
  opacity: 1;
}
.hover-parent:hover .hover-overlay .hover-overlay-content {
  transform: translate(0);
}

.more-bold {
  font-size: 4rem;
  font-weight: bold;
}

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  position: relative;
}

.hero .hero-overlay {
  background-color: rgba(60, 59, 63, 0.8);
}

.contact-info p {
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 0;
}

.friend-single .card {
  padding-top: 20px;
  margin: 10px 0 20px 0;
  background-color: rgba(214, 224, 226, 0.2);
  border-top-width: 0;
  border-bottom-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.friend-single .card .card-heading {
  padding: 0 20px;
  margin: 0;
}
.friend-single .card .card-heading.simple {
  font-size: 20px;
  font-weight: 300;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
}
.friend-single .card .card-heading.image img {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  vertical-align: top;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.friend-single .card .card-heading.image .card-heading-header {
  display: inline-block;
  vertical-align: top;
}
.friend-single .card .card-heading.image .card-heading-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}
.friend-single .card .card-heading.image .card-heading-header span {
  font-size: 12px;
  color: #999999;
}
.friend-single .card .card-body {
  padding: 0 20px;
  margin-top: 20px;
}
.friend-single .card .card-media {
  padding: 0 20px;
  margin: 0 -14px;
}
.friend-single .card .card-media img {
  max-width: 100%;
  max-height: 100%;
}
.friend-single .card .card-actions {
  min-height: 30px;
  padding: 0 20px 20px 20px;
  margin: 20px 0 0 0;
}
.friend-single .card .card-comments {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}
.friend-single .card .card-comments .comments-collapse-toggle {
  padding: 0;
  margin: 0 20px 12px 20px;
}
.friend-single .card .card-comments .comments-collapse-toggle a,
.friend-single .card .card-comments .comments-collapse-toggle span {
  padding-right: 5px;
  overflow: hidden;
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.friend-single .card-comments .media-heading {
  font-size: 13px;
  font-weight: bold;
}
.friend-single .card.people {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 300px;
  padding-top: 0;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}
.friend-single .card.people:first-child {
  margin-left: 0;
}
.friend-single .card.people .card-top {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 170px;
  height: 150px;
  background-color: #ffffff;
}
.friend-single .card.people .card-top.green {
  background-color: #53a93f;
}
.friend-single .card.people .card-top.blue {
  background-color: #427fed;
}
.friend-single .card.people .card-info {
  position: absolute;
  top: 150px;
  display: inline-block;
  width: 100%;
  height: 101px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.friend-single .card.people .card-info .title {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #404040;
}
.friend-single .card.people .card-info .desc {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  text-overflow: ellipsis;
}
.friend-single .card.people .card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  line-height: 29px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.friend-single .btn {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 18px;
  margin: 0 0.3rem;
}

.profile-avatar {
  top: -25%;
  left: 5%;
  position: absolute;
  width: 200px;
}
.profile-avatar img {
  border-radius: 100%;
  border: 5px solid #fff;
}

.EdgeButton--tertiary {
  background-color: #fff;
  border: 1px solid #66757f;
  color: #66757f;
}

.EdgeButton,
.EdgeButton:visited {
  border-radius: 100px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.profile-w {
  background: #fff;
}

.profile-head h4 {
  font-size: 1.3rem;
  margin-top: 0;
  font-weight: bold;
}

.thumb-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 4px;
}

.profile-info {
  padding-top: 5rem;
}
.profile-info span {
  display: block;
  margin-bottom: 0.4rem;
}
.profile-info span i {
  margin-right: 0.3rem;
  font-size: 1rem;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.profile-menu li {
  display: inline-block;
}
.profile-menu li > a {
  display: block;
  font-weight: bold;
  padding: 3px 15px 7px;
  color: rgba(0, 0, 0, 0.87);
}

.post-profile-head,
.time {
  display: inline-block;
}

.post-profile-head {
  font-size: 1rem;
  font-weight: bold;
}

.profile-content {
  background: #fff;
  padding: 1rem;
  font-size: 14px;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.profile-cont ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.profile-cont ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 0;
}

.profile-thumb {
  width: 70px;
  border-radius: 100%;
}

.profile-i span {
  margin-right: 0.2rem;
}
.profile-i span:last-child {
  margin: 0;
}
.profile-i p {
  margin-top: 0;
}

.profile-action a {
  display: inline-block;
  font-size: 1rem;
  margin-right: 0.8rem;
}
.profile-action a:last-child {
  margin: 0;
}

.time,
.twitter-uname,
.profile-action a {
  color: rgba(0, 0, 0, 0.54);
}

@media (max-width: 1199px) {
  .profile-avatar {
    width: 120px;
  }
}
@media (max-width: 598px) {
  .user-links {
    display: none;
  }

  .EdgeButton--tertiary {
    float: right;
    margin: 1rem;
  }

  .profile-avatar {
    top: -54%;
  }

  .profile-info {
    padding-top: 2rem;
  }
}
@media (max-width: 959px) {
  .icon-tp {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .profile {
    height: 400px !important;
  }

  .mat-card.settings-panel {
    width: 280px !important;
  }
}
.app-dark .friend-single .card.hovercard .info .desc, .app-dark .friend-single .title a {
  color: #fff;
}
.app-dark .post-module .post-content {
  background: #424242;
}
.app-dark .post-module .post-content .title {
  color: #fff;
}
.app-dark .profile {
  background: #424242;
}
.app-dark .pricing-table .pricing-option {
  background: #424242;
}
.app-dark .pricing-table .pricing-option h1, .app-dark .user-card-stats li a, .app-dark .profile-menu li > a, .app-dark .time, .app-dark .twitter-uname, .app-dark .profile-action a, .app-dark .p-list-main .p-list .bottom .left .buy i, .app-dark .p-list-main .p-list .bottom .left .buy:hover i {
  color: #fff;
}
.app-dark .user-list-card, .app-dark .profile-w, .app-dark .profile-content, .app-dark .p-list-main .p-list .bottom .left {
  background: #424242;
}

.card-comment-widget h3.mat-line {
  font-weight: bold !important;
}

.mt-0 {
  margin-bottom: 0rem !important;
}

.opacity-06 {
  opacity: 0.6;
}

.activity-head {
  font-size: 1rem !important;
  margin-bottom: 1.1rem !important;
}

/*---------------
3.5 Material 
---------------*/
[mat-raised-button],
[mat-fab],
[mat-mini-fab],
[mat-stroked-button],
[mat-button],
[mat-icon-button] {
  border-radius: 2px;
  font-weight: 400;
}
[mat-raised-button][mat-button-sm],
[mat-fab][mat-button-sm],
[mat-mini-fab][mat-button-sm],
[mat-stroked-button][mat-button-sm],
[mat-button][mat-button-sm],
[mat-icon-button][mat-button-sm] {
  font-size: 12px;
  line-height: 25px;
  padding: 2px 5px;
  min-width: 35px;
}
[mat-raised-button][mat-button-sm] mat-icon,
[mat-fab][mat-button-sm] mat-icon,
[mat-mini-fab][mat-button-sm] mat-icon,
[mat-stroked-button][mat-button-sm] mat-icon,
[mat-button][mat-button-sm] mat-icon,
[mat-icon-button][mat-button-sm] mat-icon {
  font-size: 21px;
  height: 21px;
  width: 21px;
}

.mat-raised-button.mat-primary.mat-button-disabled {
  opacity: 0.65;
  color: white;
  background-color: #ff3333;
}

[mat-icon-button][mat-button-sm] {
  padding: 0;
  width: 30px;
  height: 30px;
}
[mat-icon-button][mat-button-sm] mat-icon {
  font-size: 20px;
  line-height: 20px;
}

[mat-fab].mat-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 1rem;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: -25px;
  left: 20px;
  position: fixed;
}

[mat-fab].mat-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: fixed;
}

[mat-fab].mat-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: fixed;
}

/* $cards
 ------------------------------------------*/
body .mat-card {
  padding: 0;
  margin: 0.3333333333rem 0;
  border-radius: 2px;
  /*box-shadow: $base-card-box-shadow !important;*/
  color: rgba(0, 0, 0, 0.87);
  /* Temporary fix */
  /* https://github.com/angular/material2/issues/3131 */
  /*********/
}
body .mat-card .mat-card-header {
  height: auto;
}
body .mat-card > :first-child {
  border-radius: 2px 2px 0 0;
}
body .mat-card > :last-child {
  border-radius: 0 0 2px 2px;
}
body .mat-card [mat-card-avatar] {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
body .mat-card [mat-card-avatar] {
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0 0 1rem;
}
body .mat-card .mat-card-image {
  width: 100%;
  margin: 0;
}
body .mat-card .mat-card-image:first-child {
  margin-top: 0;
  border-radius: 2px 2px 0 0;
}
body .mat-card .mat-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-size: 1rem;
  font-weight: normal;
}
body .mat-card .mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-size: 0.8rem;
}
body .mat-card .mat-card-subtitle:first-child {
  padding-top: 1rem;
}
body .mat-card .mat-card-title:nth-child(2) {
  margin-top: -24px;
}
body .mat-card .mat-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}
body .mat-card [mat-fab-card-float] {
  top: -36px;
  position: absolute;
  right: 8px;
}
body .mat-card .mat-card-actions,
body .mat-card .mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}
body .mat-card.mat-card {
  padding: 0;
}
body .mat-card [mat-card-float-icon] {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
}
body .mat-card [mat-card-float-icon] .material-icons {
  font-size: 40px;
  opacity: 0.2;
  transform: rotate(-5deg);
}
body .mat-card [mat-card-widget] {
  height: auto;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  max-width: 100%;
  padding: 1rem;
}
body .mat-card [mat-card-widget] [mat-card-widget-title],
body .mat-card [mat-card-widget] p {
  margin: 0;
  padding: 0;
}
body .mat-card .card-image-header {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repat;
  width: 100%;
}
body .mat-card.card-widget .card-widget-content {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: -0.5rem 0 1rem 0;
}

.base-border {
  position: relative;
}
.base-border:after {
  content: "";
  display: inline-block;
  width: 7px;
  position: absolute;
  height: calc(100% + 10px);
  background: red;
  left: -35px;
  top: -3px;
  bottom: 0;
}

body [dir=rtl] .mat-card [mat-card-avatar] {
  margin: 1rem 1rem 0 0;
}
body [dir=rtl] .mat-card [mat-fab-card-float] {
  right: auto;
  left: 8px;
}
body [dir=rtl] .mat-card [mat-card-float-icon] {
  right: auto;
  left: 15px;
}

@media (max-width: 700px) {
  .mat-card-content {
    padding: 0.5rem 1rem !important;
  }
}
@media (max-width: 600px) {
  .mat-card-content {
    margin: 0 !important;
  }

  .mat-card-header-text {
    margin: 0 !important;
  }
}
.mat-dialog-container {
  background: white;
  border-radius: 2px;
}

/* $listgroup
 ------------------------------------------*/
mat-list mat-list-item, mat-list a[mat-list-item], mat-nav-list mat-list-item, mat-nav-list a[mat-list-item] {
  color: rgba(0, 0, 0, 0.87);
}

mat-list-item mat-icon[mat-list-avatar], .mat-list-item mat-icon[mat-list-avatar] {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}

mat-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

mat-list mat-list-item [mat-list-avatar], mat-list a[mat-list-item] [mat-list-avatar], mat-nav-list mat-list-item [mat-list-avatar], mat-nav-list a[mat-list-item] [mat-list-avatar] {
  min-width: 40px;
}

mat-list-item.active > .mat-list-item-content {
  background: rgba(0, 0, 0, 0.04);
}

mat-list mat-list-item .mat-list-item-content,
mat-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content {
  font-size: 0.875rem !important;
}

body {
  /*.mat-select-trigger, .mat-option {
    font-size: $font-size-base;
  }
  .mat-select-trigger {
    height: 22px;
    padding-bottom: 6px;
  }*/
}
body .mat-select-panel {
  background-color: white;
}
body .mat-select-placeholder {
  padding-left: 0;
}
body .app-dark .mat-select-panel {
  background-color: #424242;
}
body .mat-autocomplete-panel {
  background-color: white;
}
body .mat-autocomplete-panel .mat-option {
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}
body .app-dark .mat-autocomplete-panel {
  background-color: #424242;
}

.mat-menu {
  border-radius: 2px;
}

[mat-menu-item] {
  font-size: 0.875rem !important;
}

.mat-tab-label {
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  display: flex;
  justify-content: center;
}

.app-dark .mat-tab-label {
  color: white;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #ff3333;
}

.mat-error {
  color: #ff3333;
}

.mat-form-field {
  line-height: 1.275 !important;
}

.mat-red {
  background-color: #f44336 !important;
  color: white !important;
}

.mat-red-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-500 {
  background-color: #f44336 !important;
  color: white !important;
}

.mat-red-600 {
  background-color: #e53935 !important;
  color: white !important;
}

.mat-red-700 {
  background-color: #d32f2f !important;
  color: white !important;
}

.mat-red-800 {
  background-color: #c62828 !important;
  color: white !important;
}

.mat-red-900 {
  background-color: #b71c1c !important;
  color: white !important;
}

.mat-red-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-red-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}

.mat-red-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}

.mat-red-A700 {
  background-color: #d50000 !important;
  color: white !important;
}

.mat-pink {
  background-color: #e91e63 !important;
  color: white !important;
}

.mat-pink-50 {
  background-color: #fce4ec !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-100 {
  background-color: #f8bbd0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-200 {
  background-color: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-300 {
  background-color: #f06292 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-400 {
  background-color: #ec407a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-500 {
  background-color: #e91e63 !important;
  color: white !important;
}

.mat-pink-600 {
  background-color: #d81b60 !important;
  color: white !important;
}

.mat-pink-700 {
  background-color: #c2185b !important;
  color: white !important;
}

.mat-pink-800 {
  background-color: #ad1457 !important;
  color: white !important;
}

.mat-pink-900 {
  background-color: #880e4f !important;
  color: white !important;
}

.mat-pink-A100 {
  background-color: #ff80ab !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pink-A200 {
  background-color: #ff4081 !important;
  color: white !important;
}

.mat-pink-A400 {
  background-color: #f50057 !important;
  color: white !important;
}

.mat-pink-A700 {
  background-color: #c51162 !important;
  color: white !important;
}

.mat-purple {
  background-color: #9c27b0 !important;
  color: white !important;
}

.mat-purple-50 {
  background-color: #f3e5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-100 {
  background-color: #e1bee7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-200 {
  background-color: #ce93d8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-300 {
  background-color: #ba68c8 !important;
  color: white !important;
}

.mat-purple-400 {
  background-color: #ab47bc !important;
  color: white !important;
}

.mat-purple-500 {
  background-color: #9c27b0 !important;
  color: white !important;
}

.mat-purple-600 {
  background-color: #8e24aa !important;
  color: white !important;
}

.mat-purple-700 {
  background-color: #7b1fa2 !important;
  color: white !important;
}

.mat-purple-800 {
  background-color: #6a1b9a !important;
  color: white !important;
}

.mat-purple-900 {
  background-color: #4a148c !important;
  color: white !important;
}

.mat-purple-A100 {
  background-color: #ea80fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-purple-A200 {
  background-color: #e040fb !important;
  color: white !important;
}

.mat-purple-A400 {
  background-color: #d500f9 !important;
  color: white !important;
}

.mat-purple-A700 {
  background-color: #aa00ff !important;
  color: white !important;
}

.mat-deep-purple {
  background-color: #673ab7 !important;
  color: white !important;
}

.mat-deep-purple-50 {
  background-color: #ede7f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-100 {
  background-color: #d1c4e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-200 {
  background-color: #b39ddb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-300 {
  background-color: #9575cd !important;
  color: white !important;
}

.mat-deep-purple-400 {
  background-color: #7e57c2 !important;
  color: white !important;
}

.mat-deep-purple-500 {
  background-color: #673ab7 !important;
  color: white !important;
}

.mat-deep-purple-600 {
  background-color: #5e35b1 !important;
  color: white !important;
}

.mat-deep-purple-700 {
  background-color: #512da8 !important;
  color: white !important;
}

.mat-deep-purple-800 {
  background-color: #4527a0 !important;
  color: white !important;
}

.mat-deep-purple-900 {
  background-color: #311b92 !important;
  color: white !important;
}

.mat-deep-purple-A100 {
  background-color: #b388ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-purple-A200 {
  background-color: #7c4dff !important;
  color: white !important;
}

.mat-deep-purple-A400 {
  background-color: #651fff !important;
  color: white !important;
}

.mat-deep-purple-A700 {
  background-color: #6200ea !important;
  color: white !important;
}

.mat-indigo {
  background-color: #3f51b5 !important;
  color: white !important;
}

.mat-indigo-50 {
  background-color: #e8eaf6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-100 {
  background-color: #c5cae9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-200 {
  background-color: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-300 {
  background-color: #7986cb !important;
  color: white !important;
}

.mat-indigo-400 {
  background-color: #5c6bc0 !important;
  color: white !important;
}

.mat-indigo-500 {
  background-color: #3f51b5 !important;
  color: white !important;
}

.mat-indigo-600 {
  background-color: #3949ab !important;
  color: white !important;
}

.mat-indigo-700 {
  background-color: #303f9f !important;
  color: white !important;
}

.mat-indigo-800 {
  background-color: #283593 !important;
  color: white !important;
}

.mat-indigo-900 {
  background-color: #1a237e !important;
  color: white !important;
}

.mat-indigo-A100 {
  background-color: #8c9eff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-indigo-A200 {
  background-color: #536dfe !important;
  color: white !important;
}

.mat-indigo-A400 {
  background-color: #3d5afe !important;
  color: white !important;
}

.mat-indigo-A700 {
  background-color: #304ffe !important;
  color: white !important;
}

.mat-blue {
  background-color: #2196f3 !important;
  color: white !important;
}

.mat-blue-50 {
  background-color: #e3f2fd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-100 {
  background-color: #bbdefb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-200 {
  background-color: #90caf9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-300 {
  background-color: #64b5f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-400 {
  background-color: #42a5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-500 {
  background-color: #2196f3 !important;
  color: white !important;
}

.mat-blue-600 {
  background-color: #1e88e5 !important;
  color: white !important;
}

.mat-blue-700 {
  background-color: #1976d2 !important;
  color: white !important;
}

.mat-blue-800 {
  background-color: #1565c0 !important;
  color: white !important;
}

.mat-blue-900 {
  background-color: #0d47a1 !important;
  color: white !important;
}

.mat-blue-A100 {
  background-color: #82b1ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-A200 {
  background-color: #448aff !important;
  color: white !important;
}

.mat-blue-A400 {
  background-color: #2979ff !important;
  color: white !important;
}

.mat-blue-A700 {
  background-color: #2962ff !important;
  color: white !important;
}

.mat-light-blue {
  background-color: #03a9f4 !important;
  color: white !important;
}

.mat-light-blue-50 {
  background-color: #e1f5fe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-100 {
  background-color: #b3e5fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-200 {
  background-color: #81d4fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-300 {
  background-color: #4fc3f7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-400 {
  background-color: #29b6f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-500 {
  background-color: #03a9f4 !important;
  color: white !important;
}

.mat-light-blue-600 {
  background-color: #039be5 !important;
  color: white !important;
}

.mat-light-blue-700 {
  background-color: #0288d1 !important;
  color: white !important;
}

.mat-light-blue-800 {
  background-color: #0277bd !important;
  color: white !important;
}

.mat-light-blue-900 {
  background-color: #01579b !important;
  color: white !important;
}

.mat-light-blue-A100 {
  background-color: #80d8ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A200 {
  background-color: #40c4ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A400 {
  background-color: #00b0ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-blue-A700 {
  background-color: #0091ea !important;
  color: white !important;
}

.mat-cyan {
  background-color: #00bcd4 !important;
  color: white !important;
}

.mat-cyan-50 {
  background-color: #e0f7fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-100 {
  background-color: #b2ebf2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-200 {
  background-color: #80deea !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-300 {
  background-color: #4dd0e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-400 {
  background-color: #26c6da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-500 {
  background-color: #00bcd4 !important;
  color: white !important;
}

.mat-cyan-600 {
  background-color: #00acc1 !important;
  color: white !important;
}

.mat-cyan-700 {
  background-color: #0097a7 !important;
  color: white !important;
}

.mat-cyan-800 {
  background-color: #00838f !important;
  color: white !important;
}

.mat-cyan-900 {
  background-color: #006064 !important;
  color: white !important;
}

.mat-cyan-A100 {
  background-color: #84ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A200 {
  background-color: #18ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A400 {
  background-color: #00e5ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-cyan-A700 {
  background-color: #00b8d4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal {
  background-color: #009688 !important;
  color: white !important;
}

.mat-teal-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-500 {
  background-color: #009688 !important;
  color: white !important;
}

.mat-teal-600 {
  background-color: #00897b !important;
  color: white !important;
}

.mat-teal-700 {
  background-color: #00796b !important;
  color: white !important;
}

.mat-teal-800 {
  background-color: #00695c !important;
  color: white !important;
}

.mat-teal-900 {
  background-color: #004d40 !important;
  color: white !important;
}

.mat-teal-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-teal-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-50 {
  background-color: #e8f5e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-100 {
  background-color: #c8e6c9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-200 {
  background-color: #a5d6a7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-300 {
  background-color: #81c784 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-400 {
  background-color: #66bb6a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-500 {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-600 {
  background-color: #43a047 !important;
  color: white !important;
}

.mat-green-700 {
  background-color: #388e3c !important;
  color: white !important;
}

.mat-green-800 {
  background-color: #2e7d32 !important;
  color: white !important;
}

.mat-green-900 {
  background-color: #1b5e20 !important;
  color: white !important;
}

.mat-green-A100 {
  background-color: #b9f6ca !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A200 {
  background-color: #69f0ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A400 {
  background-color: #00e676 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-green-A700 {
  background-color: #00c853 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-50 {
  background-color: #f1f8e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-100 {
  background-color: #dcedc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-200 {
  background-color: #c5e1a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-300 {
  background-color: #aed581 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-400 {
  background-color: #9ccc65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-500 {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-600 {
  background-color: #7cb342 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-700 {
  background-color: #689f38 !important;
  color: white !important;
}

.mat-light-green-800 {
  background-color: #558b2f !important;
  color: white !important;
}

.mat-light-green-900 {
  background-color: #33691e !important;
  color: white !important;
}

.mat-light-green-A100 {
  background-color: #ccff90 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A200 {
  background-color: #b2ff59 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A400 {
  background-color: #76ff03 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-light-green-A700 {
  background-color: #64dd17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-50 {
  background-color: #f9fbe7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-100 {
  background-color: #f0f4c3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-200 {
  background-color: #e6ee9c !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-300 {
  background-color: #dce775 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-400 {
  background-color: #d4e157 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-500 {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-600 {
  background-color: #c0ca33 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-700 {
  background-color: #afb42b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-800 {
  background-color: #9e9d24 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-900 {
  background-color: #827717 !important;
  color: white !important;
}

.mat-lime-A100 {
  background-color: #f4ff81 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A200 {
  background-color: #eeff41 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A400 {
  background-color: #c6ff00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-lime-A700 {
  background-color: #aeea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-50 {
  background-color: #fffde7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-100 {
  background-color: #fff9c4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-200 {
  background-color: #fff59d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-300 {
  background-color: #fff176 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-400 {
  background-color: #ffee58 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-500 {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-600 {
  background-color: #fdd835 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-700 {
  background-color: #fbc02d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-800 {
  background-color: #f9a825 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-900 {
  background-color: #f57f17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A100 {
  background-color: #ffff8d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A200 {
  background-color: yellow !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A400 {
  background-color: #ffea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-yellow-A700 {
  background-color: #ffd600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-amber-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-50 {
  background-color: #fff3e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-100 {
  background-color: #ffe0b2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-200 {
  background-color: #ffcc80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-300 {
  background-color: #ffb74d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-400 {
  background-color: #ffa726 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-500 {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-600 {
  background-color: #fb8c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-700 {
  background-color: #f57c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-800 {
  background-color: #ef6c00 !important;
  color: white !important;
}

.mat-orange-900 {
  background-color: #e65100 !important;
  color: white !important;
}

.mat-orange-A100 {
  background-color: #ffd180 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A200 {
  background-color: #ffab40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A400 {
  background-color: #ff9100 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-orange-A700 {
  background-color: #ff6d00 !important;
  color: black !important;
}

.mat-deep-orange {
  background-color: #ff5722 !important;
  color: white !important;
}

.mat-deep-orange-50 {
  background-color: #fbe9e7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-100 {
  background-color: #ffccbc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-200 {
  background-color: #ffab91 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-300 {
  background-color: #ff8a65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-400 {
  background-color: #ff7043 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-500 {
  background-color: #ff5722 !important;
  color: white !important;
}

.mat-deep-orange-600 {
  background-color: #f4511e !important;
  color: white !important;
}

.mat-deep-orange-700 {
  background-color: #e64a19 !important;
  color: white !important;
}

.mat-deep-orange-800 {
  background-color: #d84315 !important;
  color: white !important;
}

.mat-deep-orange-900 {
  background-color: #bf360c !important;
  color: white !important;
}

.mat-deep-orange-A100 {
  background-color: #ff9e80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-A200 {
  background-color: #ff6e40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-deep-orange-A400 {
  background-color: #ff3d00 !important;
  color: white !important;
}

.mat-deep-orange-A700 {
  background-color: #dd2c00 !important;
  color: white !important;
}

.mat-brown {
  background-color: #795548 !important;
  color: white !important;
}

.mat-brown-50 {
  background-color: #efebe9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-300 {
  background-color: #a1887f !important;
  color: white !important;
}

.mat-brown-400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.mat-brown-500 {
  background-color: #795548 !important;
  color: white !important;
}

.mat-brown-600 {
  background-color: #6d4c41 !important;
  color: white !important;
}

.mat-brown-700 {
  background-color: #5d4037 !important;
  color: white !important;
}

.mat-brown-800 {
  background-color: #4e342e !important;
  color: white !important;
}

.mat-brown-900 {
  background-color: #3e2723 !important;
  color: white !important;
}

.mat-brown-A100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-A200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-brown-A400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.mat-brown-A700 {
  background-color: #5d4037 !important;
  color: white !important;
}

.mat-grey {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-50 {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-100 {
  background-color: whitesmoke !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-300 {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-500 {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-600 {
  background-color: #757575 !important;
  color: white !important;
}

.mat-grey-700 {
  background-color: #616161 !important;
  color: white !important;
}

.mat-grey-800 {
  background-color: #424242 !important;
  color: white !important;
}

.mat-grey-900 {
  background-color: #212121 !important;
  color: white !important;
}

.mat-grey-A100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-grey-A700 {
  background-color: #616161 !important;
  color: white !important;
}

.mat-blue-grey {
  background-color: #607d8b !important;
  color: white !important;
}

.mat-blue-grey-50 {
  background-color: #eceff1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-300 {
  background-color: #90a4ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-400 {
  background-color: #78909c !important;
  color: white !important;
}

.mat-blue-grey-500 {
  background-color: #607d8b !important;
  color: white !important;
}

.mat-blue-grey-600 {
  background-color: #546e7a !important;
  color: white !important;
}

.mat-blue-grey-700 {
  background-color: #455a64 !important;
  color: white !important;
}

.mat-blue-grey-800 {
  background-color: #37474f !important;
  color: white !important;
}

.mat-blue-grey-900 {
  background-color: #263238 !important;
  color: white !important;
}

.mat-blue-grey-A100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-A200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-blue-grey-A400 {
  background-color: #78909c !important;
  color: white !important;
}

.mat-blue-grey-A700 {
  background-color: #455a64 !important;
  color: white !important;
}

.radius-none {
  border-radius: 0;
}

.radius-round {
  border-radius: 2px;
}

.rad-full {
  border-radius: 100%;
}

.radius-circle {
  border-radius: 50%;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.block {
  display: block;
}

.button-block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: 0 !important;
}

.background-none {
  background-color: transparent !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-block {
  display: block;
}

.text-center {
  text-align: center;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.h-300px {
  height: 300px;
}

.w-100 {
  width: 100%;
}

/* Card*/
.card-container {
  padding: 1rem;
}

/*Hover Icon*/
.hover-icon i, .hover-icon mat-icon {
  transition: all 0.3s ease-in-out 0s;
  display: inline-block;
  cursor: pointer;
}
.hover-icon:hover i, .hover-icon:hover mat-icon {
  transform: scale(1.1);
  color: #2393db;
}

.mat-green.mat-raised-button {
  color: #fff !important;
}

.ma-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ma-xs {
  margin: 0.5555555556rem 0.5555555556rem !important;
}

.mt-xs {
  margin-top: 0.5555555556rem !important;
}

.mr-xs {
  margin-right: 0.5555555556rem !important;
}

.mb-xs {
  margin-bottom: 0.5555555556rem !important;
}

.ml-xs {
  margin-left: 0.5555555556rem !important;
}

.mx-xs {
  margin-right: 0.5555555556rem !important;
  margin-left: 0.5555555556rem !important;
}

.my-xs {
  margin-top: 0.5555555556rem !important;
  margin-bottom: 0.5555555556rem !important;
}

.ma-1 {
  margin: 1rem 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ma-2 {
  margin: 1.5rem 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.ma-3 {
  margin: 3rem 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.pa-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pa-xs {
  padding: 0.5555555556rem 0.5555555556rem !important;
}

.pt-xs {
  padding-top: 0.5555555556rem !important;
}

.pr-xs {
  padding-right: 0.5555555556rem !important;
}

.pb-xs {
  padding-bottom: 0.5555555556rem !important;
}

.pl-xs {
  padding-left: 0.5555555556rem !important;
}

.px-xs {
  padding-right: 0.5555555556rem !important;
  padding-left: 0.5555555556rem !important;
}

.py-xs {
  padding-top: 0.5555555556rem !important;
  padding-bottom: 0.5555555556rem !important;
}

.pa-1 {
  padding: 1rem 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pa-2 {
  padding: 1.5rem 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pa-3 {
  padding: 3rem 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-xs {
  font-size: 50% !important;
}

.text-sm {
  font-size: 70% !important;
}

.text-mat {
  font-size: 80% !important;
}

.text-df {
  font-size: 13px !important;
}

.text-lg {
  font-size: 110% !important;
}

.text-xl {
  font-size: 120% !important;
}

.text-xxl {
  font-size: 60px !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.mat-text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

a.mat-text-muted:focus, a.mat-text-muted:hover {
  color: rgba(0, 0, 0, 0.54);
}

.mat-text-default {
  color: black !important;
}

a.mat-text-default:focus, a.mat-text-default:hover {
  color: black;
}

.mat-text-primary {
  color: #ff3333 !important;
}

a.mat-text-primary:focus, a.mat-text-primary:hover {
  color: red;
}

.mat-text-warn {
  color: #454545 !important;
}

a.mat-text-warn:focus, a.mat-text-warn:hover {
  color: #2c2c2c;
}

.mat-text-accent {
  color: #fe6d34 !important;
}

a.mat-text-accent:focus, a.mat-text-accent:hover {
  color: #fe4901;
}

.app-dark .mat-text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.app-dark a.mat-text-muted:focus, .app-dark a.mat-text-muted:hover {
  color: rgba(230, 230, 230, 0.7);
}
.app-dark .mat-text-default {
  color: white !important;
}
.app-dark a.mat-text-default:focus, .app-dark a.mat-text-default:hover {
  color: #e6e6e6;
}

.column {
  padding: 0 1rem;
  margin-bottom: 2rem;
}

/*@import "@yaireo/tagify/src/tagify.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";*/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px "Nexa", "Helvetica Neue", sans-serif;;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px "Nexa", "Helvetica Neue", sans-serif;;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-card {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-select {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-slider-thumb-label-text {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-list-option {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "Nexa", "Helvetica Neue", sans-serif;;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Nexa", "Helvetica Neue", sans-serif;;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-sidenav-content {
  background: #e5e5e5 !important;
}

.menuitem-name {
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-size: 0.9em;
}

.mat-cell {
  font-size: 0.9em !important;
}

.expand .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

@media (max-width: 599px) {
  .mat-dialog-title {
    margin: 0 0 10px !important;
  }

  .mat-dialog-content {
    margin: 0 -15px !important;
    padding: 0 15px !important;
    max-height: 65vh !important;
  }

  .cdk-global-overlay-wrapper .cdk-overlay-pane {
    max-width: 92vw !important;
    min-width: 90vw !important;
  }

  .big-modal {
    max-width: 92vw;
  }

  .mat-dialog-container {
    max-width: 100vw !important;
    padding: 24px 15px !important;
  }
}
.labelStatus {
  display: inline;
  padding: 0.3em 0.6em;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.badge-primary {
  background-color: #1a1ab5;
}

.badge-info {
  background-color: #b0e5ed;
}

.badge-warning {
  background-color: #ffd731;
}

.badge-success {
  background-color: #fe6d34;
}

.badge-danger {
  background-color: #02704b;
}

.w3-small {
  font-size: 12px !important;
}

.detail-container {
  max-height: 60vh;
  overflow: auto;
  width: 100%;
}

.detail-container::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.detail-container::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 5px;
}

.mat-menu-item.thin {
  line-height: 35px !important;
  height: 35px !important;
  padding: 0 10px !important;
}

.mat-menu-item.thin .mat-icon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.mat-button-toggle-group.min .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-content-container {
  padding: 0 13px 16px 13px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-stepper-header {
  height: 40px !important;
}

.mat-expansion-panel.mini {
  border-radius: 0;
  box-shadow: none !important;
}
.mat-expansion-panel.mini .mat-expansion-panel-header {
  height: 30px !important;
}

.mat-expansion-panel.expand-min {
  border-radius: 0;
  box-shadow: none !important;
  border-bottom: 1px solid #aaa;
}
.mat-expansion-panel.expand-min .mat-expansion-panel-header {
  height: 30px !important;
  padding: 0 15px !important;
}
.mat-expansion-panel.expand-min .mat-expansion-panel-header.mat-expanded {
  background-color: #f6f6f6;
}
.mat-expansion-panel.expand-min .mat-expansion-panel-body {
  padding: 8px 2px;
}

.mat-expansion-panel-spacing.expand-min {
  margin: 0 !important;
}

.ellipsis-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
  font-size: 0.9em;
}

.mat-chip-list.multi .mat-chip-list-wrapper {
  margin: -2px 0 !important;
}

.mat-form-field.multi-select .mat-form-field-infix {
  border-top: none !important;
}

.mat-form-field.multi-select.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field.multi-select .mat-select-value-text {
  white-space: break-spaces !important;
}

table.dataTable {
  width: 100%;
}

table.dataTable td.mat-cell {
  padding-top: 10px;
  padding-bottom: 3px;
  vertical-align: baseline;
}

ul.list-plan {
  margin-block-start: 0;
  padding-inline-start: 15px;
}

.red {
  background-color: #f8d7da !important;
}