$hover-icon-color:#2393db;



.radius-none {
    border-radius: 0;
}

.radius-round {
    border-radius: $border-radius-base;
}
.rad-full{
    border-radius: 100%;
}
.radius-circle {
    border-radius: 50%;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.block {
    display: block;
}

.button-block {
    display: block;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.center-block {
    display: block;
    margin: 0 auto;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.shadow-none {
    box-shadow: none!important;
}

.border-none {
    border: 0!important;
}

.background-none {
    background-color: transparent!important;
}

//Flex
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-block {
    display: block;
}

.text-center {
    text-align: center;
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

.h-300px {
    height: 300px;
}

.w-100 {
    width: 100%;
}


/* Card*/

.card-container {
    padding: 1rem;
}


/*Hover Icon*/

.hover-icon {
    i,mat-icon {
        transition: all 0.3s ease-in-out 0s;
        display: inline-block;
        cursor: pointer;
    }
    &:hover {
        i,mat-icon {
           transform: scale(1.1);
            color: $hover-icon-color;
        }
    }
}
.mat-green.mat-raised-button{
   color:#fff !important;
}