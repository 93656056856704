/*---------------
1. Core
---------------*/


@import "core/scaffolding";
@import "core/typography";
@import "core/main-sidebar";
@import "core/main-header";
@import "core/main-panel";
@import "core/notification-sidebar";
@import "core/sessions";

/*---------------
2. Components
---------------*/

@import "components/perfect-scrollbar";
@import "components/table";
@import "components/custom";
