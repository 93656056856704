/*---------------
Perfect Scrollbar
---------------*/
$ps-border-radius: 6px !default;

$ps-rail-default-opacity: 0 !default;
$ps-rail-container-hover-opacity: 0.6 !default;
$ps-rail-hover-opacity: 0.9 !default;

$ps-bar-bg: transparent !default;
$ps-bar-container-hover-bg: #aaa !default;
$ps-bar-hover-bg: #999 !default;
$ps-rail-hover-bg: #eee !default;

// Sizes
$ps-scrollbar-x-rail-bottom: 0px !default;
$ps-scrollbar-x-rail-height: 15px !default;
$ps-scrollbar-x-bottom: 2px !default;
$ps-scrollbar-x-height: 6px !default;
$ps-scrollbar-x-hover-height: 11px !default;

$ps-scrollbar-y-rail-right: 0 !default;
$ps-scrollbar-y-rail-width: 15px !default;
$ps-scrollbar-y-right: 2px !default;
$ps-scrollbar-y-width: 6px !default;
$ps-scrollbar-y-hover-width: 11px !default;


@mixin scrollbar-rail-default($theme) {
  display: none;
  position: absolute; /* please don't change 'position' */
  opacity: map_get($theme, rail-default-opacity);
  transition: background-color .2s linear, opacity .2s linear;
}

@mixin scrollbar-rail-hover($theme) {
  background-color: map_get($theme, rail-hover-bg);
  opacity: map_get($theme, rail-hover-opacity);
}

@mixin scrollbar-default($theme) {
  position: absolute; /* please don't change 'position' */
  background-color: map_get($theme, bar-container-hover-bg);
  border-radius: map_get($theme, border-radius);
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out,
              border-radius .2s ease-in-out;
}

@mixin scrollbar-hover($theme) {
  background-color: map_get($theme, bar-hover-bg);
}

@mixin in-scrolling($theme) {
  &.ps-in-scrolling {
    &.ps-x > .ps-scrollbar-x-rail {
      @include scrollbar-rail-hover($theme);
      > .ps-scrollbar-x {
        @include scrollbar-hover($theme);
        height: map_get($theme, scrollbar-x-hover-height);
      }
    }
    &.ps-y > .ps-scrollbar-y-rail {
      @include scrollbar-rail-hover($theme);
      > .ps-scrollbar-y {
        @include scrollbar-hover($theme);
        width: map_get($theme, scrollbar-y-hover-width);
      }
    }
  }
}

// Layout and theme mixin
@mixin ps-container($theme) {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;

  // IE10+
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow: auto !important;
  }

  &.ps-active-x > .ps-scrollbar-x-rail,
  &.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: map_get($theme, bar-bg);
  }

  @include in-scrolling($theme);

  > .ps-scrollbar-x-rail {
    @include scrollbar-rail-default($theme);
    bottom: map_get($theme, scrollbar-x-rail-bottom); /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: map_get($theme, scrollbar-x-rail-height);

    > .ps-scrollbar-x {
      @include scrollbar-default($theme);
      bottom: map_get($theme, scrollbar-x-bottom); /* there must be 'bottom' for ps-scrollbar-x */
      height: map_get($theme, scrollbar-x-height);
    }
    &:hover,
    &:active {
      > .ps-scrollbar-x {
        height: map_get($theme, scrollbar-x-hover-height);
      }
    }
  }

  > .ps-scrollbar-y-rail {
    @include scrollbar-rail-default($theme);
    right: map_get($theme, scrollbar-y-rail-right); /* there must be 'right' for ps-scrollbar-y-rail */
    width: map_get($theme, scrollbar-y-rail-width);

    > .ps-scrollbar-y {
      @include scrollbar-default($theme);
      right: map_get($theme, scrollbar-y-right); /* there must be 'right' for ps-scrollbar-y */
      width: map_get($theme, scrollbar-y-width);
    }
    &:hover,
    &:active {
      > .ps-scrollbar-y {
        width: map_get($theme, scrollbar-y-hover-width);
      }
    }
  }

  &:hover {
    @include in-scrolling($theme);

    > .ps-scrollbar-x-rail,
    > .ps-scrollbar-y-rail {
      opacity: map_get($theme, rail-container-hover-opacity);
    }

    > .ps-scrollbar-x-rail:hover {
      @include scrollbar-rail-hover($theme);

      > .ps-scrollbar-x {
        @include scrollbar-hover($theme);
      }
    }

    > .ps-scrollbar-y-rail:hover {
      @include scrollbar-rail-hover($theme);

      > .ps-scrollbar-y {
        @include scrollbar-hover($theme);
      }
    }
  }
}


$ps-theme-default: (
  border-radius: $ps-border-radius,
  rail-default-opacity: $ps-rail-default-opacity,
  rail-container-hover-opacity: $ps-rail-container-hover-opacity,
  rail-hover-opacity: $ps-rail-hover-opacity,
  bar-bg: $ps-bar-bg,
  bar-container-hover-bg: $ps-bar-container-hover-bg,
  bar-hover-bg: $ps-bar-hover-bg,
  rail-hover-bg: $ps-rail-hover-bg,
  scrollbar-x-rail-bottom: $ps-scrollbar-x-rail-bottom,
  scrollbar-x-rail-height: $ps-scrollbar-x-rail-height,
  scrollbar-x-bottom: $ps-scrollbar-x-bottom,
  scrollbar-x-height: $ps-scrollbar-x-height,
  scrollbar-x-hover-height: $ps-scrollbar-x-hover-height,
  scrollbar-y-rail-right: $ps-scrollbar-y-rail-right,
  scrollbar-y-rail-width: $ps-scrollbar-y-rail-width,
  scrollbar-y-right: $ps-scrollbar-y-right,
  scrollbar-y-width: $ps-scrollbar-y-width,
  scrollbar-y-hover-width: $ps-scrollbar-y-hover-width,
);

// Default theme
.ps-container {
  @include ps-container($ps-theme-default);
}
