/*---------------
3.5 Material 
---------------*/

@import "material/buttons";
@import "material/cards";
@import "material/dialog";
@import "material/lists";
@import "material/select";
@import "material/menus";
@import "material/tabs";

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple{
    background-color: #ff3333;
}

.mat-error {
    color: #ff3333;
}

.mat-form-field {
    line-height: 1.275 !important;
}