/*
//--Theme Info--//

Theme Name: Optima Angular 5 Admin Template
Version: 1.0
Author: TrendSetter Themes


//-- Table of Content --//

---------------
1. Core
---------------
    1.1 Scaffolding
    1.2 Typography
    1.3 Main Sidebar
    1.4 Main Header
    1.5 Main Panel
    1.6 Sessions
    
---------------
2. Components
---------------
---------------
3. Material
---------------
    3.1 Material Variables
    3.2 Material Theme
    3.3 App Variables
    3.4 Mixin
    3.5 Material
    
---------------
3. Utilities
---------------*/
@use '@angular/material' as mat;

@font-face {
    font-family: "Nexa";
    src: url("../fonts/nexa/Nexa-Light.otf");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Nexa";
    src: url("../fonts/nexa/Nexa-Regular.otf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Nexa";
    src: url("../fonts/nexa/Nexa-Bold.otf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Nexa";
    src: url("../fonts/nexa/Nexa-Heavy.otf");
    font-style: normal;
    font-weight: 900;
}

@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";
@import "./w3.css";
/*@import "@yaireo/tagify/src/tagify.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";*/

$custom-typography: mat.define-typography-config($font-family: '"Nexa", "Helvetica Neue", sans-serif;',
    );
@include mat.core($custom-typography);

.mat-sidenav-content {
    background: #e5e5e5 !important;
}

.menuitem-name {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-size: 0.9em;
}

.mat-cell {
    font-size: 0.9em !important;
}

.expand .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0;
}

@media (max-width: 599px) {
    .mat-dialog-title {
        margin: 0 0 10px !important;
    }

    .mat-dialog-content {
        margin: 0 -15px !important;
        padding: 0 15px !important;
        max-height: 65vh !important;
    }

    .cdk-global-overlay-wrapper .cdk-overlay-pane {
        max-width: 92vw !important;
        min-width: 90vw !important;
    }

    .big-modal {
        max-width: 92vw;
    }

    .mat-dialog-container {
        max-width: 100vw !important;
        padding: 24px 15px !important;
    }
}

.labelStatus {
    display: inline;
    padding: 0.3em 0.6em;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.badge-primary {
    background-color: #1a1ab5;
}

.badge-info {
    background-color: #b0e5ed;
}

.badge-warning {
    background-color: #ffd731;
}

.badge-success {
    background-color: #fe6d34;
}

.badge-danger {
    background-color: #02704b;
}

.w3-small {
    font-size: 12px !important;
}

.detail-container {
    max-height: 60vh;
    overflow: auto;
    width: 100%;
}

.detail-container::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

.detail-container::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 5px;
}

.mat-menu-item.thin {
    line-height: 35px !important;
    height: 35px !important;
    padding: 0 10px !important;
}

.mat-menu-item.thin .mat-icon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.mat-button-toggle-group.min .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-content-container {
    padding: 0 13px 16px 13px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-stepper-header {
    height: 40px !important;
}

.mat-expansion-panel.mini {
    border-radius: 0;
    box-shadow: none !important;

    & .mat-expansion-panel-header {
        height: 30px !important;
    }
}

.mat-expansion-panel.expand-min {
    border-radius: 0;
    box-shadow: none !important;
    border-bottom: 1px solid #aaa;

    & .mat-expansion-panel-header {
        height: 30px !important;
        padding: 0 15px !important;

        &.mat-expanded {
            background-color: #f6f6f6;
        }
    }

    & .mat-expansion-panel-body {
        padding: 8px 2px;
    }
}

.mat-expansion-panel-spacing.expand-min {
    margin: 0 !important;
}

.ellipsis-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
    font-size: 0.9em;
}

.mat-chip-list.multi .mat-chip-list-wrapper {
    margin: -2px 0 !important;
}

.mat-form-field.multi-select .mat-form-field-infix {
    border-top: none !important;
}

.mat-form-field.multi-select.mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0 !important;
}

.mat-form-field.multi-select .mat-select-value-text {
    white-space: break-spaces !important;
}

table.dataTable {
    width: 100%;
}

table.dataTable td.mat-cell {
    padding-top: 10px;
    padding-bottom: 3px;
    vertical-align: baseline;
}

ul.list-plan {
    margin-block-start: 0;
    padding-inline-start: 15px;
}

.red {
    background-color: #f8d7da !important;
}